<h1 mat-dialog-title>{{ 'whatsNew.whatsNew' | translate }}</h1>
<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="json !== undefined">
    <h2>{{ 'whatsNew.version' | translate: {version: json.version} }}</h2>
    <div *ngFor="let feature of json.features">
      <div *ngIf="!(feature?.eap ?? false) || featureFlagService.isFeatureEnabled('early-access')">
        <h3>{{ feature.title }} <mat-chip disabled>{{ ('subscriptions.licenseType.' + (feature?.license ?? 'standard')) | translate}}</mat-chip></h3>
        {{ feature.description }}
        <a *ngIf="feature.url" href="{{feature.url}}" target="_blank">
          <mat-icon class="link_icon">open_in_new</mat-icon>
        </a>
      </div>
    </div>
    <div class="bottom_links">
      <a href="{{ json.url }}"
         target="_blank">{{ 'whatsNew.releaseNotes' | translate }}</a>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>{{ 'auditlogs.close' | translate }}</button>
</mat-dialog-actions>
