import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

export interface Placeholder {
  policyValue: string;
  viewValue: string; //can be used for upcoming support for translations
}

@Injectable({
  providedIn: 'root',
})
export class PlaceholderService {
  values: Placeholder[] = [
    {
      policyValue: '<bootvolume>',
      viewValue: 'assets.placeholders.bootVolume',
    },
    {
      policyValue: '<localvolume>',
      viewValue: 'assets.placeholders.localVolumes',
    },
    {
      policyValue: '<opticalvolume>',
      viewValue: 'assets.placeholders.opticals',
    },
    {
      policyValue: '<network shares>',
      viewValue: 'assets.placeholders.networkShares',
    },
    {
      policyValue: '<removables>',
      viewValue: 'assets.placeholders.removables',
    },
  ];

  constructor(private translationService: TranslateService) {}

  translateToLocalizedPlaceholders(location: string) {
    const placeholder = this.values.find(value => location.startsWith(value.policyValue));
    if (placeholder) {
      location = location.replace(placeholder.policyValue, this.translationService.instant(placeholder.viewValue));
    }
    return location;
  }

  translateFromLocalizedPlaceholder(location: string) {
    const placeholder = this.values.find(value =>
      location.startsWith(this.translationService.instant(value.viewValue))
    );
    if (placeholder) {
      location = location.replace(this.translationService.instant(placeholder.viewValue), placeholder.policyValue);
    }
    return location.trim();
  }
}
