import {Component} from '@angular/core';

import {HeaderComponent} from '@coreui/angular';
import {MatDialog} from '@angular/material/dialog';
import {DialogWhatsNew} from '../../../shared/components/dialog-whats-new/dialog-whats-new.component';
import {FeatureFlagService} from '../../../services/feature-flag.service';

@Component({
  selector: 'app-lancrypt-admin-header',
  templateUrl: './lancrypt-admin-header.component.html',
  styleUrls: ['./lancrypt-admin-header.component.scss'],
})
export class LancryptAdminHeaderComponent extends HeaderComponent {
  constructor(
    private dialog: MatDialog,
    protected featureFlagService: FeatureFlagService
  ) {
    super();
  }

  openWhatsNewDialog() {
    this.dialog.open(DialogWhatsNew, {
      width: '800px',
    });
  }
}
