<ng-container>
  <mat-toolbar class="header-toolbar">
    <a routerLink="/home" class="header-brand-img">
      <img cImg height="36" src="assets/company-logo.svg" alt="Utimaco"/>
    </a>
    <div class="header-tabs">
      <nav mat-tab-nav-bar [tabPanel]="tabPanel">
        <a mat-tab-link routerLinkActive="active" routerLink="/account/info" data-testid="account-nav-info">{{ 'menus.personalInfo' | translate }}</a>
        <a mat-tab-link routerLinkActive="active" routerLink="/account/contact" data-testid="account-nav-contact">{{ 'menus.contact' | translate }}</a>
        <a mat-tab-link routerLinkActive="active"
           routerLink="/account/subscriptions" data-testid="account-nav-subscriptions">{{ 'menus.subscriptions' | translate }}</a>
        <a mat-tab-link routerLinkActive="active"
           routerLink="/account/administrators"  [removeIfFeatureIsOff]="'multi-admin'" data-testid="account-nav-administrators">{{ 'menus.administrators' | translate }}</a>
        <a mat-tab-link routerLinkActive="active" routerLink="/account/clients" data-testid="account-nav-clients">{{ 'menus.clients' | translate }}</a>
        <a mat-tab-link routerLinkActive="active"
           routerLink="/account/data-export" data-testid="account-nav-export">{{ 'menus.dataExport' | translate }}</a>
        <a mat-tab-link routerLinkActive="active" routerLink="/lancrypt" data-testid="account-nav-lancrypt">{{ 'menus.dashboard' | translate }}</a>
      </nav>
      <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
    </div>
    <div class="header-controls">
      <a mat-icon-button class="header-control" matTooltip="{{ 'whatsNew.whatsNew' | translate }}"
         (click)="openWhatsNewDialog()" [removeIfFeatureIsOff]="'whats-new'" data-testid="whats-new">
        <mat-icon>new_releases</mat-icon>
      </a>
      <a mat-icon-button class="header-control" href="{{ (featureFlagService.isFeatureEnabled('early-access') ? 'userMenu.helpLinkEap' : 'userMenu.helpLink') | translate }}" target="_blank">
        <mat-icon>help_outline</mat-icon>
      </a>
      <app-user-menu></app-user-menu>
    </div>
  </mat-toolbar>
</ng-container>
