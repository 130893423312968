import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ApiClientFactoryService} from '../../../services/apiclient-factory.service';
import {
  AssetPageResultDto,
  AssetSearchRequestDto,
  AssetService,
  AssetViewDto,
  SortOrderDto,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {JwtHelperService} from '../../../services/helper/jwt-helper.service';
import {TranslationHelperService} from '../../../services/helper/translation-helper.service';
import {ToastService} from '../../../services/toaster.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {DualOptionDialog} from '../../../shared/components/dual-option-dialog/dual-option-dialog.component';
import {MatSort} from '@angular/material/sort';
import {BusyService} from '../../../shared/components/busy-service/busy.service';
import {debounceTime, distinctUntilChanged, Subject} from 'rxjs';
import {DEFAULT_DEBOUNCE_TIME, DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS} from '../../../shared/lancrypt.constants';
import {PlaceholderService} from './placeholder.service';

@Component({
  selector: 'app-lancrypt-assets',
  templateUrl: './lancrypt-assets.component.html',
  styleUrls: ['./lancrypt-assets.component.scss'],
})
export class LancryptAssetsComponent implements OnInit, AfterViewInit {
  assetService!: AssetService;

  pageSize = DEFAULT_PAGE_SIZE;
  currentPage = 0;
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;
  totalRows!: number;

  sortSettings: SortOrderDto = {
    descending: false,
    column: 'name',
  };

  nameFilterValue = '';
  nameFilter = new Subject<string>();
  nameFilterTrigger = this.nameFilter.pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged());

  dataSource: MatTableDataSource<AssetViewDto>;

  tenantId: string | undefined;

  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(
    private _jwtHelper: JwtHelperService,
    private _apiClientFactory: ApiClientFactoryService,
    protected translateService: TranslateService,
    private toastService: ToastService,
    private translationHelperService: TranslationHelperService,
    protected router: Router,
    private dialog: MatDialog,
    protected busyService: BusyService,
    private placeholderService: PlaceholderService
  ) {
    this.assetService = _apiClientFactory.getAssetService();

    this.dataSource = new MatTableDataSource<AssetViewDto>();
    this.setupSortingProperties();
  }

  ngOnInit(): void {
    this._jwtHelper.getTenantIdFromToken().then(tenantId => {
      this.tenantId = tenantId;
      this.loadAssets();
    });

    this.nameFilterTrigger.subscribe(currentValue => {
      this.currentPage = 0;
      this.nameFilterValue = currentValue.trim().toLowerCase();
      this.loadAssets();
    });
  }

  ngAfterViewInit(): void {
    this.sort!.sortChange.subscribe(sortEvent => {
      const column = this.mapSortColumn(sortEvent.active);
      this.sortSettings = {
        column: column,
        descending: sortEvent.direction === 'desc',
      };
      this.loadAssets();
    });
  }

  getColumnsToDisplay() {
    return ['name', 'location', 'type', 'access', 'date', 'delete'];
  }

  callAssetAction(id: string) {
    this.deleteAsset(id);
  }

  getAssetActionTooltip() {
    return this.translateService.instant('buttons.deleteAsset');
  }

  getAssetActionIcon() {
    return 'delete';
  }

  isRecycleBin() {
    return false;
  }

  getTitle() {
    return this.translateService.instant('headlines.assets');
  }

  applyFilter(event: Event) {
    this.nameFilter.next((event.target as HTMLInputElement).value);
  }

  newAsset() {
    return this.router.navigate(['lancrypt', 'assets', 'create-asset']);
  }

  editAsset(element: AssetViewDto) {
    if (!this.busyService.isBusy()) {
      return this.router.navigate(['lancrypt', 'assets', 'edit-asset', element.id]);
    }
    return Promise.resolve(false);
  }

  renderAccessGrantedTo(asset: AssetViewDto): string {
    return this.translationHelperService.renderUsersAndGroupsText(
      asset.grantAccessUsers.length,
      asset.grantAccessGroups.length
    );
  }

  deleteAsset(assetId: string) {
    this.dialog
      .open(DualOptionDialog, {
        width: '350px',
        data: {
          title: this.translateService.instant('assets.deleteAsset'),
          description: this.translateService.instant('assets.deleteAssetText'),
          positiveTitle: this.translateService.instant('common.confirm'),
          negativeTitle: this.translateService.instant('common.cancel'),
          helpLink: this.translateService.instant('assets.assetDeleteHelpLink'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result === true) {
          this.assetService.deleteAsset(this.tenantId!, assetId).subscribe({
            next: () => {},
            error: () => {
              this.toastService.showError(
                this.translateService.instant('common.error'),
                this.translateService.instant('assets.error.deletingAsset')
              );
            },
            complete: () => {
              this.loadAssets();
              this.toastService.showSuccess(
                this.translateService.instant('common.success'),
                this.translateService.instant('assets.assetDeleted')
              );
            },
          });
        }
      });
  }

  private loadAssets() {
    if (!this.tenantId) {
      return;
    }

    const searchRequestDto: AssetSearchRequestDto = {
      pageRequest: {
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
      },
      sortOrder: this.sortSettings,
      searchString: this.nameFilterValue,
    };

    this.assetService.getTenantAssets(searchRequestDto, this.isRecycleBin(), this.tenantId!).subscribe({
      next: async (n: AssetPageResultDto) => {
        n.content.map((value, i) => {
          n.content[i].location = this.placeholderService.translateToLocalizedPlaceholders(value.location);
        });
        this.dataSource.data = n.content;
        this.totalRows = n.totalElements;
        this.paginator.pageIndex = this.currentPage;
      },
      error: async (_: any) => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('errors.gettingAssetList')
        );
      },
    });
  }

  private setupSortingProperties() {
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'name':
          return data.name.toLowerCase();
        case 'location':
          return data.location.toLowerCase();
        case 'access':
          return this.renderAccessGrantedTo(data).toLowerCase();
        default:
          return (data as any)[sortHeaderId];
      }
    };
  }

  private mapSortColumn(column: string): string {
    switch (column) {
      case 'date':
        return this.isRecycleBin() ? 'deletedDate' : 'updateDateTime';
      default:
        return column;
    }
  }

  pageChanged(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadAssets();
  }
}
