import {Component} from '@angular/core';
import {DialogWhatsNew} from '../../../shared/components/dialog-whats-new/dialog-whats-new.component';
import {MatDialog} from '@angular/material/dialog';
import {FeatureFlagService} from '../../../services/feature-flag.service';

@Component({
  selector: 'app-account-header',
  templateUrl: './account-header.component.html',
  styleUrls: ['./account-header.component.scss'],
})
export class AccountHeaderComponent {
  constructor(
    private dialog: MatDialog,
    protected featureFlagService: FeatureFlagService
  ) {}

  openWhatsNewDialog() {
    this.dialog.open(DialogWhatsNew, {
      width: '800px',
    });
  }
}
