<h2 class="heading" mat-dialog-title>
  <span class="heading-text">{{ data.title }}</span>
  <a class="help-icon" *ngIf="data.helpLink" href="{{ data.helpLink }}" target="_blank">
    <mat-icon>help_outline</mat-icon>
  </a>
</h2>
<mat-dialog-content class="dialog-description">{{ data.description }}</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="lancrypt-primary" [mat-dialog-close]="true">{{ data.positiveTitle }}</button>
  <button mat-raised-button [mat-dialog-close]="false">{{ data.negativeTitle }}</button>
</mat-dialog-actions>
